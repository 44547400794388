import React from 'react'
import {
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {Tag} from '#/view/com/tags/TagItem'
import {ContactItemProps} from '#/view/screens/Circle'
import {useTagList} from '#/view/screens/Circle/hooks'
import {SelectFriend} from '#/view/screens/Circle/SelectFriend'
import {atoms as a, useTheme} from '#/alf'
import TagList from '../../tags/TagList'
import {EmptyBox} from '../../util/EmptyBox'

export type PostSelectTagFriendModalProps = {
  type: 'tag' | 'friend'
  friends?: ContactItemProps[]
  setFriends?: (value: ContactItemProps[]) => void
  tags?: Tag[]
  setTags?: (value: Tag[]) => void
  hideInvite?: boolean
  select1stFriends?: boolean
}
export const snapPoints = ['fullscreen']
export const Component = function PostSelectTagFriendModalComponent({
  type,
  hideInvite,
  select1stFriends,
  ...props
}: PostSelectTagFriendModalProps) {
  const t = useTheme()
  const {isMobile} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const {data: tags, isLoading} = useTagList()

  const [members, setMembers] = React.useState<ContactItemProps[]>(
    props?.friends ?? [],
  )
  const [historyMembers, setHistoryMembers] = React.useState<
    ContactItemProps[]
  >([])
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>(
    props?.tags ?? [],
  )

  function onConfirm() {
    if (type === 'friend') {
      props?.setFriends?.(select1stFriends ? historyMembers : members)
    } else {
      props?.setTags?.(selectedTags)
    }

    closeModal()
  }

  const onSelectTag = (_selected: boolean, tag: Tag) => {
    if (_selected) {
      setSelectedTags(prev => [...prev, tag])
    } else {
      setSelectedTags(prev => prev.filter(item => item.id !== tag.id))
    }
  }

  // console.log('sekect-uni', props?.friends, members);

  return (
    <SafeAreaView
      testID="postSelectUnvisibleModal"
      style={[a.flex_1, isMobile && {paddingHorizontal: 18}]}>
      <View style={[a.flex_row, a.align_center, a.justify_between]}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => closeModal()}>
          <FontAwesomeIcon size={24} icon="xmark" color={t.palette.gray_10} />
        </TouchableOpacity>
        <Text
          style={[
            a.text_xl,
            a.font_bold,
            a.flex,
            a.justify_center,
            a.text_center,
            t.atoms.text,
          ]}>
          Select {type === 'tag' ? 'Tags' : 'Friends'}
        </Text>
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            borderRadius: 6,
            backgroundColor: t.palette.primary,
            paddingHorizontal: 12,
            paddingVertical: 7,
            opacity: (
              type === 'friend' ? members?.length < 1 : selectedTags?.length < 1
            )
              ? 0.7
              : 1,
          }}
          onPress={onConfirm}>
          <Text style={[a.font_bold, {color: 'black'}]}>Done</Text>
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View
          style={[
            {
              marginTop: type === 'friend' ? 0 : 20,
              flex: 1,
              minHeight: 200,
              maxHeight: 500,
            },
          ]}>
          {type === 'friend' && (
            <SelectFriend
              circleId={-1}
              selected={members}
              historyMembers={historyMembers}
              onSelected={(list: ContactItemProps[]) => {
                setMembers(list)
              }}
              hideInvite={hideInvite}
              showCheckbox={select1stFriends ? false : true}
              dataCallback={value => {
                if (select1stFriends) {
                  setHistoryMembers(value?.filter(o => o?.degree === 1))
                }
              }}
              style={{paddingLeft: 0, paddingBottom: 0}}
            />
          )}
          {type === 'tag' &&
            (isLoading ? (
              <ActivityIndicator color={t.palette.primary} />
            ) : tags && tags?.length > 0 ? (
              <TagList
                // showCheckbox
                tags={tags}
                selected={selectedTags}
                onSelected={onSelectTag}
              />
            ) : (
              <EmptyTagCreate />
            ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const EmptyTagCreate = () => {
  // const {_} = useLingui()
  // const {closeModal} = useModalControls()
  // const t = useTheme()

  return (
    <View style={[a.flex_col, a.justify_center, a.align_center]}>
      <EmptyBox
        icon="heart"
        message="There are no tags yet."
        viewStyles={{padding: 0}}
      />
      {/* <Button
        testID="goCreateCircleBtn"
        type="default"
        onPress={() => {
          closeModal()
          // navigation.navigate('CircleCreate')
        }}
        accessibilityLabel={_(msg`Create`)}
        accessibilityHint=""
        label={_(msg`Create`)}
        labelContainerStyle={{justifyContent: 'center', padding: 4}}
        labelStyle={[a.text_md]}
        style={[
          a.mt_lg,
          a.mb_sm,
          {backgroundColor: t.palette.primary, width: 135},
        ]}
      /> */}
    </View>
  )
}
