import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {IconCircle} from '#/components/IconCircle'
import {Person3 as Person} from '#/components/icons/Person'
import {SettingsGear3 as SettingsIcon} from '#/components/icons/SettingsGear2'
import {CircleItemProps} from '.'
import {CircleAvatar} from './CircleAvatar'
import {CircleMembers} from './CircleMembers'

type Props = {
  circle: CircleItemProps
  onEdit?: (circle: CircleItemProps) => void
  onInvite?: (circle: CircleItemProps) => void
}

export function CircleItem({circle, onInvite}: Props) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {openModal} = useModalControls()
  return (
    <View style={styles.container}>
      <Button
        label="circle detail"
        onPress={() => {
          navigation.navigate('CircleDetail', {id: circle.id})
        }}
        style={[
          a.flex_row,
          a.align_center,
          a.justify_center,
          a.p_md,
          a.gap_md,
          {paddingHorizontal: 4},
        ]}>
        {/* TODO: Circle default avatar */}
        <CircleAvatar size={44} circle={circle} />
        <View style={[a.flex_col]}>
          <View style={[]}>
            <Text style={[a.font_bold, t.atoms.text]}>{circle.name}</Text>
          </View>
          <CircleMembers count={circle.memberCount} members={circle.members} />
        </View>
      </Button>
      <View style={[a.align_center, a.justify_center, a.flex_row, a.gap_lg]}>
        {circle.isOwner && (
          <Button
            label="Edit"
            onPress={() => {
              openModal({
                name: 'circle-config-menu',
                circleId: circle.id,
                circleName: circle.name,
              })
            }}>
            <IconCircle
              style={[{width: 32, height: 32}, t.atoms.modalBg2]}
              icon={SettingsIcon}
              iconStyle={[t.atoms.text_sub]}
              size="sm"
            />
          </Button>
        )}

        <Button label="Invite" onPress={() => onInvite?.(circle)}>
          <IconCircle
            style={[{width: 32, height: 32}, t.atoms.modalBg2]}
            icon={Person}
            iconStyle={[t.atoms.text_sub]}
            size="sm"
          />
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
})
