import React, {
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ActivityIndicator,
  BackHandler,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import ProgressCircle from 'react-native-progress/Circle'
import Animated, {
  FadeIn,
  FadeOut,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {
  AppBskyFeedDefs,
  AppBskyFeedGetPostThread,
  AtUri,
  BskyAgent,
} from '@atproto/api'
import {RichText} from '@atproto/api'
import {
  FinEntity,
  ForbidEntity,
} from '@atproto/api/dist/client/types/app/bsky/feed/post'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'
import {observer} from 'mobx-react-lite'

import {until} from '#/lib/async/until'
import {
  createGIFDescription,
  parseAltFromGIFDescription,
} from '#/lib/gif-alt-text'
import {DetectLanguage} from '#/lib/hooks/Tools'
import {useAnimatedScrollHandler} from '#/lib/hooks/useAnimatedScrollHandler_FIXED'
import {logEvent} from '#/lib/statsig/statsig'
import {logger} from '#/logger'
import {emitPostCreated} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useModals} from '#/state/modals'
import * as persisted from '#/state/persisted'
import {useRequireAltTextEnabled} from '#/state/preferences'
import {
  toPostLanguages,
  useLanguagePrefs,
  useLanguagePrefsApi,
} from '#/state/preferences/languages'
import {usePayWalletQuery} from '#/state/queries/link-wallet'
import {useProfileQuery} from '#/state/queries/profile'
import {Gif} from '#/state/queries/tenor'
import {ThreadgateSetting} from '#/state/queries/threadgate'
import {useUploadVideo} from '#/state/queries/video/video'
import {useAgent, useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell/composer'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {useBet} from '#/state/transaction/bet'
import {useStake} from '#/state/transaction/stake'
import {useAnalytics} from 'lib/analytics/analytics'
import * as apilib from 'lib/api/index'
import {MAX_GRAPHEME_LENGTH} from 'lib/constants'
import {useIsKeyboardVisible} from 'lib/hooks/useIsKeyboardVisible'
import {cleanError} from 'lib/strings/errors'
import {insertMentionAt} from 'lib/strings/mention-manip'
import {shortenLinks} from 'lib/strings/rich-text-manip'
import {colors, s} from 'lib/styles'
import {isAndroid, isIOS, isNative, isWeb} from 'platform/detection'
import {useDialogStateControlContext} from 'state/dialogs'
import {GalleryModel} from 'state/models/media/gallery'
import {State as VideoUploadState} from 'state/queries/video/video'
import {ComposerOpts} from 'state/shell/composer'
import {TargetIcon} from '#/view/icons/ModalIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {ArrowTriangleBottom_Stroke2_Corner1 as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
import {SwitchAccountIcon} from '#/components/icons/StakeIcons'
import * as Prompt from '#/components/Prompt'
import {
  SELET_TYPE,
  VisibleRangeType,
} from '../modals/PostModal/PostSelectCircle'
import {TVisible} from '../modals/PostModal/Visible'
import {SelectTarget} from '../modals/PostTarget'
import {QuoteEmbed, QuoteX} from '../util/post-embeds/QuoteEmbed'
import {usePostMask} from '../util/post-mask'
import {usePostStepStore} from '../util/sdlStore/PostStepStore'
import {usePostTargetStore} from '../util/sdlStore/PostTargetStore'
import {useTgStore} from '../util/sdlStore/TgStore'
import {Text} from '../util/text/Text'
import * as Toast from '../util/Toast'
import {ExternalEmbed} from './ExternalEmbed'
import {GifAltText} from './GifAltText'
import {Gallery} from './photos/Gallery'
import {SelectPhotoBtn} from './photos/SelectPhotoBtn'
import {Credibility} from './postBox/Credibility'
import {TargetPreview} from './postBox/TargetPreview'
import {SuggestedLanguage} from './select-language/SuggestedLanguage'
import {SdlTextInput} from './text-input/SdlTextInput'
import {TextInput, TextInputRef} from './text-input/TextInput'
import {useExternalLinkFetch} from './useExternalLinkFetch'
import {VideoPreview} from './videos/VideoPreview'
import {VideoTranscodeProgress} from './videos/VideoTranscodeProgress'

type CancelRef = {
  onPressCancel: () => void
}

type Props = ComposerOpts
export const ComposePost = observer(function ComposePost({
  replyTo,
  onPost,
  quote: initQuote,
  mention: initMention,
  text: initText,
  imageUris: initImageUris,
  cancelRef,
}: Props & {
  cancelRef?: React.RefObject<CancelRef>
}) {
  const queryClient = useQueryClient()
  const agent = useAgent()
  const {isModalActive} = useModals()
  const {closeComposer} = useComposerControls()
  const {track} = useAnalytics()
  const {_} = useLingui()
  const requireAltTextEnabled = useRequireAltTextEnabled()
  const langPrefs = useLanguagePrefs()
  const setLangPrefs = useLanguagePrefsApi()
  const textInput = useRef<TextInputRef>(null)
  const discardPromptControl = Prompt.usePromptControl()
  const {closeAllDialogs} = useDialogStateControlContext()
  const {closeAllModals, openModal} = useModalControls()
  const {currentAccount} = useSession()
  const {data: payWalletAddress} = usePayWalletQuery(
    currentAccount?.did ?? '',
    true,
    0,
  )
  const {data: currentProfile} = useProfileQuery({did: currentAccount?.did})
  const t = useTheme()
  const [title, setTitle] = useState('')
  const [targets, setTargets] = useState<FinEntity[]>([])
  const {
    targetStore: {selectedArray},
    setSelectedTargetStore,
  } = usePostTargetStore()
  const {
    stepStore: {guarantorParams},
  } = usePostStepStore()
  const selectedFeed = useSelectedFeed()

  const {bind: hasBindTelegram} = useTgStore()

  const [visible, setVisible] = useState<TVisible>(
    selectedFeed?.includes('circle') ? 'Circle' : 'Public',
  )
  const [visibleRange, setVisibleRange] = useState<VisibleRangeType>({
    visibleType: 1,
    visibleCircles: [],
    visibleFriends: [],
    unVisibleTags: [],
    unVisibleFriends: [],
  })

  const {
    finishStake,
    finishBet,
    setStakeParams,
    setBetParams,
    setPostInfo,
    nextStep,
  } = usePostStepStore()

  const {showStakeModal, amount, response, onStake} = useStake({
    type: 'create-post-stake',
  })
  const {
    showBetModal,
    amount: betAmount,
    response: betResponse,
    onCreateBet,
  } = useBet({type: 'create-bet'})
  const {sltAccount, markAsControl, makeAsDialog, isAnonymous} = usePostMask({
    visible,
  })

  useEffect(() => {
    if (response) {
      finishStake()
    }
  }, [response, finishStake])

  useEffect(() => {
    if (betResponse) {
      finishBet()
    }
  }, [betResponse, finishBet])

  useEffect(() => {
    setTargets(
      selectedArray.map(tar => {
        return {
          id: tar.id,
          name: tar.name,
          type: tar.type,
          avatar: tar.logo,
        }
      }),
    )
  }, [selectedArray])

  const [isKeyboardVisible] = useIsKeyboardVisible({iosUseWillEvents: true})
  const [isProcessing, setIsProcessing] = useState(false)
  const [
    ,
    // error,
    setError,
  ] = useState('')
  const [richtext, setRichText] = useState(
    new RichText({
      text: initText
        ? initText
        : initMention
        ? insertMentionAt(
            `@${initMention}`,
            initMention.length + 1,
            `${initMention}`,
          ) // insert mention if passed in
        : '',
    }),
  )
  const graphemeLength = useMemo(() => {
    return shortenLinks(richtext).graphemeLength
  }, [richtext])
  const [quote, setQuote] = useState<ComposerOpts['quote'] | undefined>(
    initQuote,
  )

  const {clearVideo, state: videoUploadState} = useUploadVideo({
    setStatus: () => {},
    onSuccess: () => {
      if (publishOnUpload) {
        onPressPublish(true)
      }
    },
  })
  const [publishOnUpload, setPublishOnUpload] = useState(false)

  const {extLink, setExtLink} = useExternalLinkFetch({setQuote})
  const [extGif, setExtGif] = useState<Gif>()
  const [labels] = useState<string[]>([])
  const [threadgate] = useState<ThreadgateSetting[]>([])

  const gallery = useMemo(
    () => new GalleryModel(initImageUris),
    [initImageUris],
  )
  const onClose = useCallback(() => {
    closeComposer()
  }, [closeComposer])

  const insets = useSafeAreaInsets()
  const viewStyles = useMemo(
    () => ({
      paddingTop: isAndroid ? insets.top : 0,
      paddingBottom:
        isAndroid || (isIOS && !isKeyboardVisible) ? insets.bottom : 0,
    }),
    [insets, isKeyboardVisible],
  )

  const onPressCancel = useCallback(() => {
    if (graphemeLength > 0 || !gallery.isEmpty || extGif) {
      closeAllDialogs()
      Keyboard.dismiss()
      discardPromptControl.open()
    } else {
      onClose()
    }
  }, [
    extGif,
    graphemeLength,
    gallery.isEmpty,
    closeAllDialogs,
    discardPromptControl,
    onClose,
  ])

  useImperativeHandle(cancelRef, () => ({onPressCancel}))

  // On Android, pressing Back should ask confirmation.
  useEffect(() => {
    if (!isAndroid) {
      return
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        if (closeAllDialogs() || closeAllModals()) {
          return true
        }
        onPressCancel()
        return true
      },
    )
    return () => {
      backHandler.remove()
    }
  }, [onPressCancel, closeAllDialogs, closeAllModals])

  // listen to escape key on desktop web
  const onEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onPressCancel()
      }
    },
    [onPressCancel],
  )
  useEffect(() => {
    if (isWeb && !isModalActive) {
      window.addEventListener('keydown', onEscape)
      return () => window.removeEventListener('keydown', onEscape)
    }
  }, [onEscape, isModalActive])

  const onNewLink = useCallback(
    (uri: string) => {
      if (extLink != null) return
      setExtLink({uri, isLoading: true})
    },
    [extLink, setExtLink],
  )

  const onPhotoPasted = useCallback(
    async (uri: string) => {
      track('Composer:PastedPhotos')
      await gallery.paste(uri)
    },
    [gallery, track],
  )

  const isAltTextRequiredAndMissing = useMemo(() => {
    if (!requireAltTextEnabled) return false

    if (gallery.needsAltText) return true
    if (extGif) {
      if (!extLink?.meta?.description) return true

      const parsedAlt = parseAltFromGIFDescription(extLink.meta.description)
      if (!parsedAlt.isPreferred) return true
    }
    return false
  }, [gallery.needsAltText, extLink, extGif, requireAltTextEnabled])

  const onPressVisible = () => {
    if (hasBindTelegram) {
      openModal({
        name: 'visible',
        setVisible: setVisible,
        visible: visible,
        visibleRange,
        setVisibleRange,
      })
    } else {
      Toast.show(_(msg`Please link your Telegram to enable Circle`), 'comment')
    }
  }

  const onPressPublish = async (finishedUploading?: boolean) => {
    if (isProcessing || graphemeLength > MAX_GRAPHEME_LENGTH) {
      return
    }

    if (isAltTextRequiredAndMissing) {
      return
    }

    if (
      !finishedUploading &&
      videoUploadState.status !== 'idle' &&
      videoUploadState.asset
    ) {
      setPublishOnUpload(true)
      return
    }

    setError('')

    if (
      richtext.text.trim().length === 0 &&
      gallery.isEmpty &&
      !extLink &&
      !quote
    ) {
      setError(_(msg`Did you want to say anything?`))
      return
    }
    if (extLink?.isLoading) {
      setError(_(msg`Please wait for your link card to finish loading`))
      return
    }

    setIsProcessing(true)
    if (amount || betAmount || guarantorParams) {
      openModal({
        name: 'post-step',
      })
    }

    let postUri,
      postCid,
      detectLanguage = await DetectLanguage(title + richtext.text)

    try {
      const requestParams: apilib.PostOpts = {
        rawText: richtext.text,
        replyTo: replyTo?.uri,
        images: gallery.images,
        quote,
        extLink,
        labels,
        threadgate,
        title: title,
        targets: targets.length > 0 ? targets : undefined,
        // onStateChange: setProcessingState,
        onStateChange: () => {},
        langs: detectLanguage
          ? [detectLanguage]
          : toPostLanguages(langPrefs.postLanguage),
      }

      if (!quote && !replyTo) {
        let visibleCircles: string[] = [visible as string]
        let forbidIds: ForbidEntity[] = []
        let visibleIds: ForbidEntity[] = []
        if (visibleRange?.visibleType === 2) {
          visibleIds = [{type: 'CF', value: 'NA'}]
        } else if (visibleRange?.visibleType === 3) {
          visibleIds = visibleRange.visibleCircles
            ?.map(o => {
              return {type: 'CIRCLE', value: o?.id}
            })
            .concat(
              visibleRange.visibleFriends?.map(o => {
                if (o?.from === 'telegram') {
                  return {type: 'TELE', value: o?.id}
                } else if (o?.from === 'twitter') {
                  return {type: 'TWITTER', value: o?.id}
                } else {
                  return {type: 'SIPZ', value: o?.id}
                }
              }),
            )
        } else if (visibleRange?.visibleType === 4) {
          forbidIds = visibleRange.unVisibleTags
            ?.map(o => {
              return {type: 'TAG', value: o?.id + ''}
            })
            .concat(
              visibleRange.unVisibleFriends?.map(o => {
                if (o?.from === 'telegram') {
                  return {type: 'TELE', value: o?.id}
                } else if (o?.from === 'twitter') {
                  return {type: 'TWITTER', value: o?.id}
                } else {
                  return {type: 'SIPZ', value: o?.id}
                }
              }),
            )
        }
        requestParams.visibleCircles = visibleCircles
        requestParams.forbidIds = forbidIds
        requestParams.visibleIds = visibleIds
        requestParams.createDid = sltAccount?.did
        requestParams.hideAuthor =
          visible === 'Public' ? 'false' : isAnonymous?.toString()
      }
      const result = await apilib.post(agent, requestParams)
      postUri = result.uri
      postCid = result.cid
      try {
        await whenAppViewReady(agent, postUri, res => {
          const thread = res.data.thread
          return AppBskyFeedDefs.isThreadViewPost(thread)
        })
      } catch (waitErr: any) {
        logger.error(waitErr, {
          message: `Waiting for app view failed`,
        })
        // Keep going because the post *was* published.
      }
    } catch (e: any) {
      logger.error(e, {
        message: `Composer: create post failed`,
        hasImages: gallery.size > 0,
      })

      if (extLink) {
        setExtLink({
          ...extLink,
          isLoading: true,
          localThumb: undefined,
        } as apilib.ExternalEmbedDraft)
      }
      let err = cleanError(e.message)
      if (err.includes('not locate record')) {
        err = _(
          msg`We're sorry! The post you are replying to has been deleted.`,
        )
      }
      setError(err)
      setIsProcessing(false)
      return
    } finally {
      if (postUri) {
        logEvent('post:create', {
          imageCount: gallery.size,
          isReply: replyTo != null,
          hasLink: extLink != null,
          hasQuote: quote != null,
          langs: langPrefs.postLanguage,
          logContext: 'Composer',
        })
      }
      track('Create Post', {
        imageCount: gallery.size,
      })
      setSelectedTargetStore([])
      if (replyTo && replyTo.uri) track('Post:Reply')
    }
    setLangPrefs.savePostLanguageToHistory()
    onPost?.(postUri)
    Toast.show(
      replyTo
        ? _(msg`Your reply has been published`)
        : _(msg`Your post has been published`),
    )

    if (postUri && !replyTo) {
      queryClient.invalidateQueries({
        queryKey: [
          'post-feed',
          `author|${currentProfile?.did}|posts_and_author_threads`,
          {},
        ],
      })
      emitPostCreated()
      if (amount || betAmount || guarantorParams) {
        if (amount) {
          setStakeParams({
            rKey: new AtUri(postUri).rkey,
            transPostAuthor: {
              did: currentAccount?.did || '',
              payWallet: payWalletAddress,
              avatar: sltAccount?.avatar ?? currentProfile?.avatar ?? '',
              displayName:
                currentProfile?.displayName ??
                formatHandler(currentProfile?.handle || ''),
              createDid: sltAccount?.did ?? (currentAccount?.did || ''),
            },
          })
        }
        if (betAmount) {
          setBetParams({
            rKey: new AtUri(postUri).rkey,
            transPostAuthor: {
              did: currentAccount?.did || '',
              payWallet: payWalletAddress,
              avatar: sltAccount?.avatar ?? currentProfile?.avatar ?? '',
              displayName:
                currentProfile?.displayName ??
                formatHandler(currentProfile?.handle || ''),
              createDid: sltAccount?.did ?? (currentAccount?.did || ''),
            },
          })
        }
        if (guarantorParams) {
          setPostInfo({
            uri: postUri || '',
            cid: postCid,
            did: currentAccount?.did || '',
          })
        }
        nextStep()
      } else {
        // detect linked wallet
        const hideRemind = persisted.get('hideLinkWalletRemind')
        if (
          !hideRemind &&
          !replyTo &&
          !quote &&
          (!payWalletAddress || payWalletAddress === '')
        ) {
          openModal({name: 'detect-link-wallet'})
        }
        onClose()
      }
    } else {
      onClose()
    }
  }

  const canPost = useMemo(
    () =>
      graphemeLength <= MAX_GRAPHEME_LENGTH &&
      !isAltTextRequiredAndMissing &&
      graphemeLength > 0,
    [graphemeLength, isAltTextRequiredAndMissing],
  )
  const selectTextInputPlaceholder = replyTo
    ? _(msg`Write your reply`)
    : _(msg`Share what you want to say...`)

  const canSelectImages =
    gallery.size < 4 &&
    !extLink &&
    videoUploadState.status === 'idle' &&
    !videoUploadState.video

  const handleChangeGifAltText = useCallback(
    (altText: string) => {
      setExtLink(ext =>
        ext && ext.meta
          ? {
              ...ext,
              meta: {
                ...ext.meta,
                description: createGIFDescription(
                  ext.meta.title ?? '',
                  altText,
                ),
              },
            }
          : ext,
      )
    },
    [setExtLink],
  )

  const {
    scrollHandler,
    onScrollViewContentSizeChange,
    onScrollViewLayout,
    topBarAnimatedStyle,
    // bottomBarAnimatedStyle,
  } = useAnimatedBorders()

  const keyboardVerticalOffset = useKeyboardVerticalOffset()

  const {height: screenHeight} = Dimensions.get('window')
  const calculatedHeight = screenHeight - 390

  return (
    <KeyboardAvoidingView
      testID="composePostView"
      behavior={isIOS ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={a.flex_1}>
      {replyTo ? (
        <TouchableWithoutFeedback
          accessibilityRole="combobox"
          onPress={() => {
            !isWeb && Keyboard.dismiss()
          }}>
          <View
            style={[a.flex_1, viewStyles]}
            aria-modal
            accessibilityViewIsModal>
            <Animated.View style={topBarAnimatedStyle}>
              <View
                style={[
                  styles.topbarInner,
                  {borderBottomWidth: 1},
                  t.atoms.line,
                  t.atoms.modalBg,
                ]}>
                <TouchableOpacity
                  accessibilityRole="button"
                  style={{}}
                  onPress={onPressCancel}>
                  <Text style={t.atoms.text}>
                    <Trans>Cancel</Trans>
                  </Text>
                </TouchableOpacity>
                <View style={a.flex_1} />

                {isProcessing ? (
                  <>
                    {/* <Text style={pal.textLight}>{processingState}</Text> */}
                    <View style={styles.postBtn}>
                      <ActivityIndicator color={t.palette.primary} />
                    </View>
                  </>
                ) : (
                  <>
                    {/* <LabelsBtn
                  labels={labels}
                  onChange={setLabels}
                  hasMedia={hasMedia}
                /> */}
                    <TouchableOpacity
                      accessibilityRole="button"
                      style={{
                        borderRadius: 25,
                        backgroundColor: t.palette.primary,
                        paddingHorizontal: 10,
                        paddingVertical: canPost ? 5 : 7,
                        opacity: canPost ? 1 : 0.5,
                      }}
                      onPress={() => onPressPublish()}>
                      <Text style={[a.font_bold, {color: 'black'}]}>
                        {replyTo ? (
                          <Trans context="action">Reply</Trans>
                        ) : (
                          <Trans context="action">Spill it</Trans>
                        )}
                      </Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            </Animated.View>
            <Animated.ScrollView
              onScroll={scrollHandler}
              style={[styles.scrollView, t.atoms.modalBg2]}
              keyboardShouldPersistTaps="always"
              onContentSizeChange={onScrollViewContentSizeChange}
              onLayout={onScrollViewLayout}>
              <View
                style={[
                  styles.textInputLayout,
                  isNative && styles.textInputLayoutMobile,
                ]}>
                <TextInput
                  ref={textInput}
                  richtext={richtext}
                  placeholder={selectTextInputPlaceholder}
                  autoFocus
                  setRichText={setRichText}
                  onPhotoPasted={onPhotoPasted}
                  onPressPublish={() => onPressPublish()}
                  onNewLink={onNewLink}
                  onError={setError}
                  accessible={true}
                  accessibilityLabel={_(msg`Write post`)}
                  accessibilityHint={_(
                    msg`Compose posts up to ${MAX_GRAPHEME_LENGTH} characters in length`,
                  )}
                />
              </View>

              <Gallery gallery={gallery} />
              {gallery.isEmpty && extLink && (
                <View style={a.relative}>
                  <ExternalEmbed
                    link={extLink}
                    gif={extGif}
                    onRemove={() => {
                      setExtLink(undefined)
                      setExtGif(undefined)
                    }}
                  />
                  <GifAltText
                    link={extLink}
                    gif={extGif}
                    onSubmit={handleChangeGifAltText}
                  />
                </View>
              )}

              <View style={[a.mt_md]}>
                {quote ? (
                  <View style={[s.mt5, s.mb2, isWeb && s.mb10]}>
                    <View style={{pointerEvents: 'none'}}>
                      <QuoteEmbed quote={quote} />
                    </View>
                    {quote.uri !== initQuote?.uri && (
                      <QuoteX onRemove={() => setQuote(undefined)} />
                    )}
                  </View>
                ) : null}
                {videoUploadState.status === 'compressing' &&
                videoUploadState.asset ? (
                  <VideoTranscodeProgress
                    asset={videoUploadState.asset}
                    progress={videoUploadState.progress}
                  />
                ) : videoUploadState.video ? (
                  // remove suspense when we get rid of lazy
                  <Suspense fallback={null}>
                    <VideoPreview
                      video={videoUploadState.video}
                      clear={clearVideo}
                    />
                  </Suspense>
                ) : null}
              </View>
            </Animated.ScrollView>
            <SuggestedLanguage text={richtext.text} />

            <View
              style={[
                t.atoms.modalBg2,
                t.atoms.border_contrast_medium,
                styles.bottomBar,
              ]}>
              {videoUploadState.status !== 'idle' ? (
                <VideoUploadToolbar state={videoUploadState} />
              ) : (
                <ToolbarWrapper style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <SelectPhotoBtn
                    gallery={gallery}
                    disabled={!canSelectImages}
                  />
                  {!replyTo && <TargetIcon />}
                </ToolbarWrapper>
              )}
              <View style={a.flex_1} />
            </View>
          </View>
        </TouchableWithoutFeedback>
      ) : (
        <>
          <Animated.View style={[topBarAnimatedStyle]}>
            <TouchableWithoutFeedback
              accessibilityRole="combobox"
              onPress={() => {
                !isWeb && Keyboard.dismiss()
              }}>
              <View
                style={[
                  styles.topbarInner,
                  {
                    marginTop: isAndroid ? insets.top : 0,
                    borderBottomWidth: 1,
                  },
                  t.atoms.line,
                  t.atoms.modalBg,
                ]}>
                <TouchableOpacity
                  accessibilityRole="button"
                  style={{}}
                  onPress={onPressCancel}>
                  <Text style={t.atoms.text}>
                    <Trans>Cancel</Trans>
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  accessibilityRole="button"
                  style={[a.flex_1, a.justify_center]}
                  onPress={() => {
                    markAsControl.open()
                  }}>
                  <Text
                    style={[
                      a.text_md,
                      t.atoms.text,
                      a.font_bold,
                      a.text_center,
                    ]}>
                    Tea
                  </Text>
                  {sltAccount && !quote && (
                    <View
                      style={[
                        a.flex_1,
                        a.flex_row,
                        a.align_center,
                        a.justify_center,
                        a.px_md,
                      ]}>
                      <Text
                        style={[
                          a.text_xs,
                          {color: t.palette.gray_10, width: 50},
                        ]}>
                        <Trans>Spill as</Trans>
                      </Text>
                      <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={[
                          a.text_xs,
                          a.font_bold,
                          {color: t.palette.primary_active},
                        ]}>
                        {isAnonymous && visible === 'Circle'
                          ? 'Anonymous'
                          : sltAccount?.name && sltAccount?.name !== ''
                          ? sltAccount?.name
                          : formatHandler(sltAccount?.handle)}
                      </Text>
                      <SwitchAccountIcon style={[a.ml_xs]} />
                    </View>
                  )}
                </TouchableOpacity>

                {isProcessing ? (
                  <>
                    {/* <Text style={pal.textLight}>{processingState}</Text> */}
                    <View style={styles.postBtn}>
                      <ActivityIndicator color={t.palette.primary} />
                    </View>
                  </>
                ) : (
                  <TouchableOpacity
                    accessibilityRole="button"
                    style={{
                      borderRadius: 25,
                      backgroundColor: t.palette.primary,
                      paddingHorizontal: 10,
                      paddingVertical: canPost ? 5 : 7,
                      opacity: canPost ? 1 : 0.5,
                    }}
                    onPress={() => onPressPublish()}>
                    <Text style={[a.font_bold, {color: 'black'}]}>
                      {replyTo ? (
                        <Trans context="action">Reply</Trans>
                      ) : (
                        <Trans context="action">Spill it</Trans>
                      )}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </TouchableWithoutFeedback>
          </Animated.View>
          <Animated.ScrollView
            style={[styles.scrollView, t.atoms.bg]}
            keyboardShouldPersistTaps="always">
            <TouchableWithoutFeedback
              accessibilityRole="combobox"
              onPress={() => {
                !isWeb && Keyboard.dismiss()
              }}>
              <View>
                <View
                  style={[
                    a.flex_1,
                    t.atoms.modalBg2,
                    {height: calculatedHeight},
                  ]}
                  aria-modal
                  accessibilityViewIsModal>
                  {/* Visible to */}
                  {!quote && (
                    <View style={[{paddingHorizontal: 12, paddingTop: 16}]}>
                      <TouchableOpacity
                        accessibilityRole="button"
                        style={{flexDirection: 'row', alignItems: 'center'}}
                        onPress={onPressVisible}>
                        <Text
                          style={[t.atoms.text, a.font_bold, {fontSize: 14}]}>
                          <Trans>Visible To</Trans>
                        </Text>
                        <View style={{flex: 1}} />
                        <View style={[a.flex_row, a.align_center]}>
                          <Text
                            style={[
                              t.atoms.text,
                              {fontSize: 14, marginRight: 6},
                            ]}>
                            {visible}
                            {visible === 'Circle' ? ' : ' : ''}
                          </Text>
                          {visible === 'Circle' && (
                            <>
                              {visibleRange.visibleType === 1 && (
                                <Text style={[t.atoms.text]}>
                                  {SELET_TYPE?.[0]?.name}
                                </Text>
                              )}
                              {visibleRange.visibleType === 2 && (
                                <Text style={[t.atoms.text]}>
                                  {SELET_TYPE?.[1]?.name}
                                </Text>
                              )}
                              {visibleRange.visibleType === 3 && (
                                <Text style={[t.atoms.text]}>
                                  {SELET_TYPE?.[2]?.name} (
                                  {visibleRange.visibleFriends?.length > 0
                                    ? `${visibleRange.visibleFriends?.length} Friends`
                                    : ''}
                                  {visibleRange.visibleFriends?.length > 0 &&
                                  visibleRange.visibleCircles?.length > 0
                                    ? ' and '
                                    : ''}
                                  {visibleRange.visibleCircles?.length > 0
                                    ? `${visibleRange.visibleCircles?.length} Circles`
                                    : ''}
                                  )
                                </Text>
                              )}
                              {visibleRange.visibleType === 4 && (
                                <Text style={[t.atoms.text]}>
                                  <Text style={{color: '#FF543D'}}>
                                    {SELET_TYPE?.[3]?.name}{' '}
                                  </Text>
                                  (
                                  {visibleRange.unVisibleTags?.length > 0
                                    ? `${visibleRange.unVisibleTags?.length} Tags`
                                    : ''}
                                  {visibleRange.unVisibleFriends?.length > 0 &&
                                  visibleRange.unVisibleTags?.length > 0
                                    ? ' and '
                                    : ''}
                                  {visibleRange.unVisibleFriends?.length > 0
                                    ? `${visibleRange.unVisibleFriends?.length} Friends`
                                    : ''}
                                  )
                                </Text>
                              )}
                            </>
                          )}
                        </View>
                        <ArrowTriangleBottom
                          style={{
                            color: t.palette.contrast_975,
                            width: 16,
                            height: 16,
                            marginLeft: 6,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                  {/* Title */}
                  <View
                    style={[
                      styles.textInputLayout,
                      isNative && styles.textInputLayoutMobile,
                    ]}>
                    {!quote && (
                      <View style={{paddingHorizontal: 12}}>
                        <SdlTextInput
                          text={title}
                          setText={setTitle}
                          style={styles.sdlInput}
                          placeholder="Title (Optional)"
                          amxLength={100}
                        />
                      </View>
                    )}
                    <View style={{maxHeight: 300}}>
                      <Animated.ScrollView
                        onScroll={scrollHandler}
                        keyboardShouldPersistTaps="always"
                        onContentSizeChange={onScrollViewContentSizeChange}
                        onLayout={onScrollViewLayout}>
                        <TextInput
                          ref={textInput}
                          richtext={richtext}
                          placeholder={selectTextInputPlaceholder}
                          autoFocus
                          setRichText={setRichText}
                          onPhotoPasted={onPhotoPasted}
                          onPressPublish={() => onPressPublish()}
                          onNewLink={onNewLink}
                          onError={setError}
                          accessible={true}
                          accessibilityLabel={_(msg`Write post`)}
                          accessibilityHint={_(
                            msg`Compose posts up to ${MAX_GRAPHEME_LENGTH} characters in length`,
                          )}
                        />
                      </Animated.ScrollView>
                    </View>
                  </View>

                  {gallery.isEmpty && extLink && (
                    <View style={[a.relative, a.px_md]}>
                      <ExternalEmbed
                        link={extLink}
                        gif={extGif}
                        onRemove={() => {
                          setExtLink(undefined)
                          setExtGif(undefined)
                        }}
                      />
                      <GifAltText
                        link={extLink}
                        gif={extGif}
                        onSubmit={handleChangeGifAltText}
                      />
                    </View>
                  )}

                  <View style={[a.mt_md]}>
                    {quote ? (
                      <View style={[s.mt5, s.mb2, a.px_md, isWeb && s.mb10]}>
                        <View style={{pointerEvents: 'none'}}>
                          <QuoteEmbed quote={quote} />
                        </View>
                        {quote.uri !== initQuote?.uri && (
                          <QuoteX onRemove={() => setQuote(undefined)} />
                        )}
                      </View>
                    ) : null}
                    {/* {videoUploadState.status === 'compressing' &&
                    videoUploadState.asset ? (
                      <VideoTranscodeProgress
                        asset={videoUploadState.asset}
                        progress={videoUploadState.progress}
                      />
                    ) : videoUploadState.video ? (
                      // remove suspense when we get rid of lazy
                      <Suspense fallback={null}>
                        <VideoPreview
                          video={videoUploadState.video}
                          clear={clearVideo}
                        />
                      </Suspense>
                    ) : null} */}
                  </View>

                  {/* <SuggestedLanguage text={richtext.text} /> */}
                </View>

                {/* preview image and target========================================================================= */}
                <View style={[t.atoms.modalBg2, {paddingHorizontal: 12}]}>
                  <Gallery gallery={gallery} />
                  <TargetPreview previewArray={selectedArray} />
                </View>

                {/* bottom control img and target ==================================================================== */}
                <View
                  style={[
                    t.atoms.modalBg2,
                    t.atoms.border_contrast_medium,
                    styles.bottomBar,
                  ]}>
                  {videoUploadState.status !== 'idle' ? (
                    <VideoUploadToolbar state={videoUploadState} />
                  ) : (
                    <ToolbarWrapper
                      style={[a.flex_row, a.align_center, a.gap_xs]}>
                      <View style={!canSelectImages && {opacity: 0.5}}>
                        <SelectPhotoBtn
                          gallery={gallery}
                          disabled={!canSelectImages}
                        />
                      </View>
                      {!quote && (
                        <SelectTarget init={() => textInput.current?.blur()} />
                      )}
                    </ToolbarWrapper>
                  )}
                  <View style={a.flex_1} />
                </View>

                {!quote && (
                  <>
                    {visible === 'Public' && (
                      <View style={[{borderTopWidth: 1}, t.atoms.line]}>
                        <Credibility
                          showStakeModal={showStakeModal}
                          showBetModal={showBetModal}
                          amount={amount}
                          onStake={onStake}
                          betAmount={betAmount}
                          onCreateBet={onCreateBet}
                        />
                        <View style={{height: 3, width: 3}} />
                      </View>
                    )}
                  </>
                )}
              </View>
            </TouchableWithoutFeedback>
          </Animated.ScrollView>
        </>
      )}
      <Prompt.Basic
        control={discardPromptControl}
        title={_(msg`Discard draft?`)}
        description={_(msg`Are you sure you'd like to discard this draft?`)}
        onConfirm={onClose}
        confirmButtonCta={_(msg`Discard`)}
        confirmButtonColor="negative"
      />
      {makeAsDialog}
    </KeyboardAvoidingView>
  )
})

export function useComposerCancelRef() {
  return useRef<CancelRef>(null)
}

function useAnimatedBorders() {
  const t = useTheme()
  const hasScrolledTop = useSharedValue(0)
  const hasScrolledBottom = useSharedValue(0)
  const contentOffset = useSharedValue(0)
  const scrollViewHeight = useSharedValue(Infinity)
  const contentHeight = useSharedValue(0)

  /**
   * Make sure to run this on the UI thread!
   */
  const showHideBottomBorder = useCallback(
    ({
      newContentHeight,
      newContentOffset,
      newScrollViewHeight,
    }: {
      newContentHeight?: number
      newContentOffset?: number
      newScrollViewHeight?: number
    }) => {
      'worklet'

      if (typeof newContentHeight === 'number')
        contentHeight.value = Math.floor(newContentHeight)
      if (typeof newContentOffset === 'number')
        contentOffset.value = Math.floor(newContentOffset)
      if (typeof newScrollViewHeight === 'number')
        scrollViewHeight.value = Math.floor(newScrollViewHeight)

      hasScrolledBottom.value = withTiming(
        contentHeight.value - contentOffset.value - 5 > scrollViewHeight.value
          ? 1
          : 0,
      )
    },
    [contentHeight, contentOffset, scrollViewHeight, hasScrolledBottom],
  )

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      'worklet'
      hasScrolledTop.value = withTiming(event.contentOffset.y > 0 ? 1 : 0)
      showHideBottomBorder({
        newContentOffset: event.contentOffset.y,
        newContentHeight: event.contentSize.height,
        newScrollViewHeight: event.layoutMeasurement.height,
      })
    },
  })

  const onScrollViewContentSizeChange = useCallback(
    (_width: number, height: number) => {
      'worklet'
      showHideBottomBorder({
        newContentHeight: height,
      })
    },
    [showHideBottomBorder],
  )

  const onScrollViewLayout = useCallback(
    (evt: LayoutChangeEvent) => {
      'worklet'
      showHideBottomBorder({
        newScrollViewHeight: evt.nativeEvent.layout.height,
      })
    },
    [showHideBottomBorder],
  )

  const topBarAnimatedStyle = useAnimatedStyle(() => {
    return {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: interpolateColor(
        hasScrolledTop.value,
        [0, 1],
        ['transparent', t.atoms.border_contrast_medium.borderColor],
      ),
    }
  })
  const bottomBarAnimatedStyle = useAnimatedStyle(() => {
    return {
      borderTopWidth: StyleSheet.hairlineWidth,
      borderColor: interpolateColor(
        hasScrolledBottom.value,
        [0, 1],
        ['transparent', t.atoms.border_contrast_medium.borderColor],
      ),
    }
  })

  return {
    scrollHandler,
    onScrollViewContentSizeChange,
    onScrollViewLayout,
    topBarAnimatedStyle,
    bottomBarAnimatedStyle,
  }
}

function useKeyboardVerticalOffset() {
  const {top} = useSafeAreaInsets()

  // Android etc
  if (!isIOS) return 0

  // iPhone SE
  if (top === 20) return 40

  // all other iPhones
  return top + 10
}

async function whenAppViewReady(
  agent: BskyAgent,
  uri: string,
  fn: (res: AppBskyFeedGetPostThread.Response) => boolean,
) {
  await until(
    5, // 5 tries
    1e3, // 1s delay between tries
    fn,
    () =>
      agent.app.bsky.feed.getPostThread({
        uri,
        depth: 0,
      }),
  )
}

const styles = StyleSheet.create({
  topbarInner: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    height: 54,
    gap: 4,
  },
  postBtn: {
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 6,
    marginLeft: 12,
  },
  errorLine: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.red1,
    borderRadius: 6,
    marginHorizontal: 16,
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginBottom: 8,
  },
  reminderLine: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6,
    marginHorizontal: 16,
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginBottom: 8,
  },
  errorIcon: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.red4,
    color: colors.red4,
    borderRadius: 30,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  scrollView: {
    flex: 1,
  },
  textInputLayout: {
    paddingTop: 4,
    // paddingHorizontal: 10
  },
  sdlInput: {
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    marginBottom: 10,
  },
  textInputLayoutMobile: {
    flex: 1,
  },
  addExtLinkBtn: {
    borderWidth: 1,
    borderRadius: 24,
    paddingHorizontal: 16,
    paddingVertical: 12,
    marginHorizontal: 10,
    marginBottom: 4,
  },
  bottomBar: {
    flexDirection: 'row',
    paddingVertical: 4,
    // should be 8 but due to visual alignment we have to fudge it
    paddingLeft: 7,
    paddingRight: 16,
    alignItems: 'center',
    borderTopWidth: 0,
  },
})

function ToolbarWrapper({
  style,
  children,
}: {
  style: StyleProp<ViewStyle>
  children: React.ReactNode
}) {
  if (isWeb) return children
  return (
    <Animated.View
      style={style}
      entering={FadeIn.duration(400)}
      exiting={FadeOut.duration(400)}>
      {children}
    </Animated.View>
  )
}

function VideoUploadToolbar({state}: {state: VideoUploadState}) {
  const t = useTheme()

  const progress =
    state.status === 'compressing' || state.status === 'uploading'
      ? state.progress
      : state.jobStatus?.progress ?? 100

  return (
    <ToolbarWrapper
      style={[a.gap_sm, a.flex_row, a.align_center, {paddingVertical: 5}]}>
      <ProgressCircle
        size={30}
        borderWidth={1}
        borderColor={t.atoms.border_contrast_low.borderColor}
        color={t.palette.primary_500}
        progress={progress}
      />
      <Text>{state.status}</Text>
    </ToolbarWrapper>
  )
}
