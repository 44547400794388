import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {AppBskyFeedPost, RichText as RichTextAPI} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {isDev} from '#/lib/constants'
import {TranslateText} from '#/lib/hooks/Tools'
import {shortenLinks, stripInvalidMentions} from '#/lib/strings/rich-text-manip'
import {useLanguagePrefs} from '#/state/preferences'
import {useAgent} from '#/state/session'
import * as Toast from '#/view/com/util/Toast'

export function TranslationWidget({
  translate,
}: {
  translate: {
    displayOriginalText: () => void
    translatePost: () => void
    hasTranslated: boolean
    isLanguageConsistent: boolean
  }
}) {
  return (
    <>
      {!translate.isLanguageConsistent && (
        <View style={styles.page}>
          {!translate.hasTranslated ? (
            <TouchableOpacity
              accessibilityRole="button"
              onPress={translate.translatePost}>
              <Text
                style={{
                  marginTop: -15,
                  fontSize: 17,
                  color: '#C28C00',
                }}>
                <Trans>Translate</Trans>
              </Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              accessibilityRole="button"
              onPress={translate.displayOriginalText}>
              <Text
                style={{
                  marginTop: -15,
                  fontSize: 17,
                  color: '#C28C00',
                }}>
                <Trans>Translated by google</Trans>
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  page: {
    paddingVertical: 10,
    marginTop: 10,
    marginBottom: -10,
  },
})

export function useTranslationWidget({
  record,
  shouldTrimArticle,
}: {
  record: AppBskyFeedPost.Record
  shouldTrimArticle?: boolean
}) {
  const langPrefs = useLanguagePrefs()
  const {_} = useLingui()
  const [hasMore, setHasMore] = useState(false)
  const [richText, setRichText] = useState<RichTextAPI>(() => {
    let recordText = record.text
    if (shouldTrimArticle) {
      const {text, hasMoreText} = trimArticle(record.text)
      if (hasMoreText) {
        setHasMore(true)
        recordText = text
      }
    }
    return new RichTextAPI({
      text: recordText,
      facets: record.facets,
    })
  })
  const [translateTitle, setTranslateTitle] = useState('')
  const [hasTranslated, setHasTranslated] = useState(false)

  const agent = useAgent()

  const isLanguageConsistent = useMemo(() => {
    const temp = record?.langs || []
    return temp.includes(langPrefs.primaryLanguage)
  }, [langPrefs.primaryLanguage, record?.langs])

  const originRichText = useMemo(
    () =>
      new RichTextAPI({
        text: record.text,
        facets: record.facets,
      }),
    [record],
  )

  const translatePost = useCallback(async () => {
    if (isLanguageConsistent) return
    const transHandler = new TranslateHandler(record.text)
    TranslateText(
      [record.title || '', transHandler.sanitizeForTranslation(record.text)],
      langPrefs.primaryLanguage,
    )
      .then(source => {
        let sourceText = transHandler.restoreFromTranslation(source[1])
        if (shouldTrimArticle) {
          const {text, hasMoreText} = trimArticle(sourceText)
          if (hasMoreText) {
            setHasMore(true)
            sourceText = text
          }
        }
        let rt = new RichTextAPI(
          {text: sourceText},
          {
            cleanNewlines: true,
          },
        )
        rt.detectFacets(agent).then(() => {
          rt = shortenLinks(rt)
          setRichText(stripInvalidMentions(rt))
          setHasTranslated(true)
          setTranslateTitle(source[0])
        })
      })
      .catch(() => {
        Toast.show(_(msg`Sorry! the translation request failed.`), 'warning')
      })
  }, [
    _,
    agent,
    isLanguageConsistent,
    langPrefs.primaryLanguage,
    record.text,
    record.title,
    shouldTrimArticle,
  ])

  useEffect(() => {
    if (langPrefs.autoTranslate && !isDev) {
      translatePost()
    }
  }, [translatePost, record, langPrefs])

  const displayOriginalText = useCallback(() => {
    setRichText(originRichText)
    setHasTranslated(false)
    setTranslateTitle('')
  }, [originRichText])

  return {
    translateTitle,
    hasTranslated,
    translatePost,
    displayOriginalText,
    richText,
    langPrefs,
    isLanguageConsistent,
    hasMore,
  }
}

function trimArticle(text: string) {
  const MAX_LENGTH = 280
  const {totalCount, result} = countCharacters(text)
  const hasMoreText = totalCount > MAX_LENGTH

  let trimmedText = hasMoreText ? result + '...' : text

  if (hasMoreText) {
    const lastSpaceIndex = trimmedText.lastIndexOf(' ')
    if (lastSpaceIndex >= 0 && MAX_LENGTH - lastSpaceIndex < 10) {
      trimmedText = trimmedText.slice(0, lastSpaceIndex) + '...'
    }
  }
  return {text: trimmedText, hasMoreText}
}

function countCharacters(str: string) {
  let totalCount = 0
  let result = ''
  for (const char of str) {
    if (/^[\u4e00-\u9fa5]$/.test(char)) {
      totalCount += 2
    } else {
      totalCount += 1
    }
    if (totalCount <= 280) {
      result += char
    }
  }
  return {totalCount, result}
}

class TranslateHandler {
  private replacementMapping: Map<string, string>
  private restorationMapping: Map<string, string>

  constructor(text: string) {
    this.replacementMapping = new Map<string, string>()
    this.restorationMapping = new Map<string, string>()

    text.split(/\s+/).forEach((word, index) => {
      if (word.startsWith('#') || word.startsWith('@')) {
        this.replacementMapping.set(word, `#S_i_p_z${index}`)
        this.restorationMapping.set(`#S_i_p_z${index}`, word)
      }
    })
  }

  sanitizeForTranslation(text: string): string {
    return Array.from(this.replacementMapping.entries()).reduce(
      (acc, [key, value]) => {
        return acc.replace(key, value)
      },
      text,
    )
  }

  restoreFromTranslation(text: string): string {
    return Array.from(this.restorationMapping.entries()).reduce(
      (acc, [key, value]) => {
        return acc.replace(key, value)
      },
      text,
    )
  }
}
