import React from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  Pressable,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {fuzzyMatch} from '#/lib/hooks/Tools'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {CircleItemProps} from '#/view/screens/Circle'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {useCircleList} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {CheckedIcon, UncheckedIcon} from '#/components/icons/StakeIcons'
import {EmptyBox} from '../../util/EmptyBox'
import {Button} from '../../util/forms/Button'
import {SearchInput} from '../../util/forms/SearchInput'
import {CircleListType, VisibleRangeType} from './PostSelectCircle'

export type SelectCircleListModalProps = {
  list: CircleListType[]
  setList: React.Dispatch<React.SetStateAction<CircleListType[]>>
  visibleRange: VisibleRangeType
}
export const snapPoints = ['fullscreen']
export const Component = function SelectCircleListModalComponent(
  props: SelectCircleListModalProps,
) {
  const t = useTheme()
  const {_} = useLingui()
  const {data: circles, isLoading} = useCircleList()
  const {closeModal} = useModalControls()
  const {isMobile} = useWebMediaQueries()
  const navigation = useNavigation<NavigationProp>()

  const [query, setQuery] = React.useState('')
  const [list, setList] = React.useState<CircleListType[]>(props?.list)

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }
  const onPressCancelSearch = () => {
    setQuery('')
  }

  const toggleSelect = React.useCallback(
    (id: string) => {
      setList(prevItems =>
        prevItems.map(item =>
          item.id === id ? {...item, isSelected: !item.isSelected} : item,
        ),
      )
    },
    [setList],
  )

  function onConfirm() {
    props?.setList?.(list)
    closeModal()
  }

  React.useEffect(() => {
    setList(
      (circles ?? []).map((o: CircleItemProps) => {
        return {
          ...o,
          isSelected: props?.visibleRange?.visibleCircles?.some(
            item => item.id === o.id,
          ),
        }
      }),
    )
  }, [circles, props?.visibleRange.visibleCircles, setList])

  const renderItem = React.useCallback(
    ({item}: {item: CircleListType}) => {
      return (
        <View
          style={[
            a.flex_row,
            a.align_center,
            a.mb_md,
            a.mr_md,
            a.justify_between,
          ]}
          key={item?.id}>
          <View style={[a.flex_row, a.align_center, a.gap_sm]}>
            <CircleAvatar size={56} circle={item} />
            <View style={[a.gap_xs]}>
              <Text style={[a.text_md, a.font_bold, t.atoms.text]}>
                {item?.name}
              </Text>
              <View>
                {/* <StackAvatar
                  data={item?.members?.map(m => m?.avatar ?? '')}
                  maxCount={4}
                /> */}
                <Text style={[a.text_xs, {color: t.palette.gray_10}]}>
                  {item?.memberCount} Members
                </Text>
              </View>
            </View>
          </View>
          <Pressable
            accessibilityRole="button"
            onPress={() => toggleSelect(item.id)}>
            {item?.isSelected ? (
              <CheckedIcon />
            ) : (
              <UncheckedIcon primaryColor={t.atoms.text.color} />
            )}
          </Pressable>
        </View>
      )
    },
    [t.palette.gray_10, t.atoms.text, toggleSelect],
  )

  return (
    <SafeAreaView
      testID="postSelectUnvisibleModal"
      style={[a.flex_1, isMobile && {paddingHorizontal: 18}]}>
      <View style={[a.flex_row, a.align_center, a.justify_between]}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => closeModal()}>
          <FontAwesomeIcon size={24} icon="xmark" color={t.palette.gray_10} />
        </TouchableOpacity>
        <Text
          style={[
            a.text_xl,
            a.font_bold,
            a.flex,
            a.justify_center,
            a.text_center,
            t.atoms.text,
          ]}>
          <Trans>Select Circles</Trans>
        </Text>
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            borderRadius: 6,
            backgroundColor: t.palette.primary,
            paddingHorizontal: 12,
            paddingVertical: 7,
            opacity: list?.filter(o => o?.isSelected)?.length < 1 ? 0.7 : 1,
          }}
          onPress={onConfirm}>
          <Text style={[a.font_bold, {color: 'black'}]}>Done</Text>
        </TouchableOpacity>
      </View>
      <View style={[a.flex_1, a.mt_2xl]}>
        <View style={[a.mb_md, {height: 42}]}>
          <SearchInput
            query={query}
            onSubmitQuery={() => {}}
            onChangeQuery={onChangeQuery}
            onPressCancelSearch={onPressCancelSearch}
          />
        </View>
        <View style={[a.flex_row, a.flex_wrap]}>
          {list
            .filter(item => item.isSelected)
            ?.map(item => {
              return (
                <CircleCapsule
                  data={item}
                  key={item?.id}
                  onDelete={() => toggleSelect(item.id)}
                />
              )
            })}
        </View>
        <View style={[a.flex_1, a.gap_md, a.mb_sm, a.mt_md, {minHeight: 100}]}>
          {isLoading ? (
            <ActivityIndicator color={t.palette.primary} />
          ) : list && list?.length > 0 ? (
            <RNFlatList
              testID="postSelectCirclesFlatlist"
              data={list?.filter(o => {
                if (query && o?.name) {
                  return fuzzyMatch(o?.name, query) ? o : null
                } else {
                  return o
                }
              })}
              renderItem={renderItem}
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <View style={[a.flex_col, a.justify_center, a.align_center]}>
              <EmptyBox
                icon="heart"
                message="There are no circle yet. you can create it."
                viewStyles={{padding: 0}}
              />
              <Button
                testID="goCreateCircleBtn"
                type="default"
                onPress={() => {
                  closeModal()
                  navigation.navigate('CircleCreate')
                }}
                accessibilityLabel={_(msg`Create`)}
                accessibilityHint=""
                label={_(msg`Create`)}
                labelContainerStyle={{justifyContent: 'center', padding: 4}}
                labelStyle={[a.text_md]}
                style={[
                  a.mt_lg,
                  a.mb_sm,
                  {backgroundColor: t.palette.primary, width: 135},
                ]}
              />
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  )
}

const CircleCapsule = ({
  data,
  onDelete,
}: {
  data: CircleItemProps
  onDelete: any
}) => {
  const t = useTheme()
  return (
    <View
      key={data?.id}
      style={{
        flexDirection: 'row',
        paddingVertical: 4,
        paddingLeft: 4,
        paddingRight: onDelete ? 5 : 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: t.atoms.bg_capsule.backgroundColor, //config.background,
        borderRadius: 6,
        marginRight: 10,
        marginBottom: 5,
        maxWidth: '100%',
      }}>
      <CircleAvatar size={20} circle={data} />
      <View>
        <Text
          style={{
            color: t.atoms.text_capsule.color, //'#5E6272',
            fontSize: 12,
            marginLeft: 6,
          }}>
          {data?.name}
        </Text>
      </View>
      {onDelete && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            marginLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onDelete}>
          <CloseIcon circleColor={'#000'} />
        </TouchableOpacity>
      )}
    </View>
  )
}
