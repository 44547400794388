import React, {useCallback, useMemo} from 'react'
import {Pressable, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

// import {useNavigation} from '@react-navigation/native'
import {fuzzyMatch} from '#/lib/hooks/Tools'
import {
  CommonNavigatorParams,
  NativeStackScreenProps,
  // NavigationProp,
} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
// import {Button} from '#/components/Button'
import {useDialogControl} from '#/components/Dialog'
import {Loader} from '#/components/Loader'
import * as Prompt from '#/components/Prompt'
import ContactList from '../Telegram/ContactList'
import {CircleMemberItem} from './CircleMemberItem'
import {
  removeDuplicatesWithSet,
  telegramContactList,
  useCircleAccount,
  useCircleDeleteMemberMutation,
  useCircleItem,
  useTelegramContactList,
} from './hooks'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'CircleMembers'>
export default function CircleMembers({route}: Props) {
  const {_} = useLingui()
  const t = useTheme()
  const [query, setQuery] = React.useState('')
  const [memberId, setMemberId] = React.useState<string | undefined>()
  const {data} = useCircleAccount(Number(route.params.id))
  const deleteCircleMember = useCircleDeleteMemberMutation()
  const prompt = useDialogControl()
  // const navigation = useNavigation<NavigationProp>()
  const {data: currentRole} = useCircleItem(Number(route.params.id))
  const queryClient = useQueryClient()
  const {openModal} = useModalControls()
  const {status: recommendLoading, data: recommends = []} =
    useTelegramContactList({
      circleId: Number(route.params.id),
      query,
    })
  const unbindData = removeDuplicatesWithSet(
    recommends.filter(o => !o?.isBindSipz),
    'id',
  )

  const checkIsOwner = useCallback(
    (arr: any[]) => {
      return arr.includes(currentRole?.creator)
    },
    [currentRole?.creator],
  )

  const ownerItem = useMemo(() => {
    return data?.find(item =>
      item?.sipz_dids?.includes(currentRole?.creator || ''),
    )
  }, [currentRole?.creator, data])

  const contacts = useMemo(() => {
    if (!data) return []
    if (!query) {
      return data
    } else {
      return data.filter(item => {
        return (
          fuzzyMatch(item.user_name || '', query) ||
          fuzzyMatch(item.source_user_id || '', query)
        )
      })
    }
  }, [data, query])

  const renderButton = () => {
    return (
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => {}}
        style={styles.remove}>
        {/* <Text>
          <Trans>Remove</Trans>
        </Text> */}
      </TouchableOpacity>
    )
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const onRemoveCircleMember = async () => {
    if (!memberId) {
      Toast.show('Please select a member to remove')
      return
    }
    await deleteCircleMember.mutateAsync({
      circleId: +route.params.id,
      members: [memberId],
    })
  }

  return (
    <CenteredView style={{paddingHorizontal: 10}}>
      <ViewHeader
        canGoBack
        showBorder={false}
        showHorizontalBorder={false}
        showOnDesktop
        title={_(msg`Members`)}
        // subtitle={subtitle}
        renderButton={renderButton}
      />
      <View style={{marginBottom: 10, height: 44}}>
        <SearchInput
          query={query}
          onSubmitQuery={() => {}}
          onChangeQuery={setQuery}
          onPressCancelSearch={onPressCancelSearch}
          style={[a.border, t.atoms.input_bg, t.atoms.input_border]}
        />
      </View>
      {ownerItem && (
        <CircleMemberItem
          item={ownerItem}
          isOwner={true}
          onDelete={(id: string) => {
            setMemberId(id)
            prompt.open()
          }}
        />
      )}
      {contacts?.map(item => {
        const itemIsOwner = checkIsOwner(item?.sipz_dids || [])
        if (itemIsOwner) {
          return null
        }
        return (
          <CircleMemberItem
            key={item.source_user_id}
            item={item}
            isOwner={itemIsOwner}
            onDelete={(id: string) => {
              setMemberId(id)
              prompt.open()
            }}
          />
        )
      })}
      <View
        style={[
          a.flex_row,
          a.flex_1,
          a.justify_between,
          a.align_center,
          a.my_md,
        ]}>
        <Text style={[a.font_bold, a.text_md, t.atoms.text]}>
          <Trans>Recommend friends</Trans>
        </Text>
        {unbindData?.length > 0 && (
          <Pressable
            accessibilityRole="button"
            onPress={() => {
              openModal({
                name: 'telegram',
                currentStep: TelegramBindStep.SendMessage,
                userIds: unbindData?.map(item => item.id),
                onCallBack: () => {
                  queryClient.invalidateQueries({
                    queryKey: [telegramContactList],
                  })
                },
              })
            }}
            style={[
              a.justify_center,
              a.align_center,
              {
                width: 64,
                height: 24,
                borderRadius: 100,
                backgroundColor: t.palette.gray_15,
              },
            ]}>
            {recommends.filter(o => !o?.isBindSipz)?.length > 0 && (
              <View>
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    {color: t.palette.white},
                  ]}>
                  <Trans>Invite all</Trans>
                </Text>
              </View>
            )}
          </Pressable>
        )}
      </View>
      {recommendLoading === 'pending' ? (
        <View
          style={[
            a.flex_1,
            a.h_full,
            a.align_center,
            a.justify_center,
            a.p_2xl,
          ]}>
          <Loader size="xl" />
        </View>
      ) : (
        <ContactList
          users={unbindData}
          showCheckbox={false}
          showDegree={false}
          showInvite={true}
          showTag={false}
        />
      )}
      {/* <Button
        label="Invite more"
        onPress={() => {
          navigation.navigate('CircleInvite', {id: route.params.id})
        }}>
        <Text style={[a.text_md, t.atoms.text]}>
          <Trans>Invite more</Trans>
        </Text>
      </Button> */}
      <Prompt.Outer control={prompt}>
        <Prompt.TitleText>Delete Member</Prompt.TitleText>
        <Prompt.DescriptionText>
          Remove{' '}
          {
            contacts.find(contact => contact.source_user_id === memberId)
              ?.user_name
          }{' '}
          from this circle? They will no longer be able to view Circle Tea.
        </Prompt.DescriptionText>
        <Prompt.Actions>
          <Prompt.Action
            cta="Delete"
            color="negative"
            onPress={onRemoveCircleMember}
          />
          <Prompt.Cancel />
        </Prompt.Actions>
      </Prompt.Outer>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  abilityItem: {
    padding: 10,
    fontSize: 14,
    borderRadius: 16,
    borderBottomLeftRadius: 0,
  },
  remove: {
    height: '100%',
    justifyContent: 'center',
  },
})
